<template>
  <main class="kb-main" id="kb-mypage-interest">
    <lxp-main-header :show-back="true">
      <template v-slot:inner>
        <div class="header-column">
          <div class="buttons">
            <button class="kb-btn kb-btn-outline rounded-lg kb-btn-delete" :disabled="isSelectCnt === 0"><span class="text">삭제</span></button>
            <button class="kb-btn kb-btn-outline rounded-lg kb-btn-check" @click="allSelCardFunc"><span class="text">전체선택</span></button>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content main-component">
      <div class="mypage-body">
        <div class="course-list-container" data-view="note">
          <div :class="{ 'course-list-container' : activeMenu === '연수', 'hrdcloud-list-container' : activeMenu === 'HRD 클라우드', 'hrdcloud-list-wrapper' : activeMenu === '클라우드 Easy' }" data-view="row">
            <MyTrain v-if="activeMenu === '연수'" @select-card-func="selCardFunc" v-model="cardSelMode" />
            <MyHrdCloud v-else-if="activeMenu === 'HRD 클라우드'"  @select-card-func="selCardFunc" v-model="cardSelMode"/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ref, onMounted} from 'vue';
import MyTrain from "@/components/my/MyTrain";
import MyHrdCloud from "@/components/my/MyHrdCloud";
import {useRoute} from "vue-router";
import LxpMainHeader from "@/components/common/LxpMainHeader";

export default {
  name: 'MyKeepTrainEdit',
  components: {LxpMainHeader, MyHrdCloud, MyTrain},
  setup() {

    const route = useRoute();

    const isActive = ref(false);
    const activeMenu = ref('연수');

    onMounted(() => {
      activeMenu.value = route.params.type;
    })

    const isSelectCnt = ref(0);
    const cardSelMode = ref('');

    const selCardFunc = (selectCnt) => {
      isSelectCnt.value = selectCnt;
      cardSelMode.value = '';

    }
    const allSelCardFunc = () => {
      cardSelMode.value = 'all';
    }

    return {
      isActive,
      activeMenu,
      isSelectCnt,
      cardSelMode,

      selCardFunc,
      allSelCardFunc,
    }
  }
};
</script>
