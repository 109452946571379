<template>
  <div class="hrdcloud-list-wrapper">
    <ul class="hrdcloud-list" data-grid="4">
      <li v-for="(item, idx) in items" :key="idx" class="hrdcloud-item">
        <article class="hrdcloud-card edit-card" :class="`edit-card-`+idx" @click="selectCrse(idx)">
          <div class="hrdcloud-image">
            <div class="image">
              <a href="javascript:" class="image-link">
                <img :src="item.thumnailSrc" alt="임시이미지" />
              </a>
            </div>
            <div class="badges">
              <div v-if="item.seriesYn === 'y'" class="badge-active">
                <span class="text">시리즈</span>
              </div>
              <div v-else-if="item.seriesYn !== 'y' && item.completeYn === 'y'" class="badge-inactive">
                <span class="text">완료</span>
                <i class="icon-check"></i>
              </div>
            </div>
          </div>
          <div class="hrdcloud-content">
            <div class="content-category">
              <span class="text">{{item.hrdCategory}}</span>
            </div>
            <div class="content-title">
              <h5 class="title"><a href="javascript:" class="title-link">{{item.hrdTitle}}</a></h5>
            </div>
            <div class="content-info">
              <div class="info">
                <i class="icon-views"></i>
                <span class="text">{{item.viewCnt}}</span>
              </div>
              <div class="info">
                <i class="icon-like"></i>
                <span class="text">{{item.favoriteCnt}}</span>
              </div>
            </div>
            <div class="content-meta">
              <span class="text">{{item.regDttm}}</span>
              <span class="text">{{item.playTime}}</span>
            </div>
            <div class="content-interest">
              <button class="kb-btn-interest" data-content="담기">
                <i class="icon-interest"></i>
              </button>
            </div>
          </div>
        </article>
      </li>
    </ul>
    <!-- pagination -->
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    <!-- //pagination -->
  </div>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck} from "@/assets/js/util";
import {ACT_GET_HRDCLOUD_LIST} from "@/store/modules/hrdcloud/hrdcloud";

export default {
  name: "MyHrdCloud",
  components: {CommonPaginationFront},
  props:{
    modelValue: String
  },
  emits:['selectCardFunc', 'update:modelValue'],
  setup(props, {emit}) {
    const isActive = ref(false);
    const activeMenu = ref('연수');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const items = ref([]);
    const paging = ref(initPaging(route));

    const getMyKeepHrdCloudList = () => {
      store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LIST}`, {}).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.value = getPaging(res)
        }else{
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getMyKeepHrdCloudList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {type: route.query.type, pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => route.query, () => {
      if (route.query.pageNo) {
        paging.value.pageNo = route.query.pageNo;
      } else {
        paging.value.pageNo = 1;
      }
      if (route.query.pageSize) {
        paging.value.pageSize = route.query.pageSize;
      }
      getMyKeepHrdCloudList();
    });

    watch(() => activeMenu.value, () => {
      getMyKeepHrdCloudList();
    })

    watch(() => props.modelValue, () => {
      if(props.modelValue === 'all') {
        allSelectCrse();
      }
    })

    const isSelectCnt = ref(0);
    const selectCrse = (idx) => {
      if(route.params.type === 'HRD 클라우드') {
        if (document.querySelector('.edit-card-' + idx).classList.contains('is-active')) {
          document.querySelector('.edit-card-' + idx).classList.remove('is-active');
          isSelectCnt.value--;
        } else {
          document.querySelector('.edit-card-' + idx).classList.add('is-active');
          isSelectCnt.value++;
        }
        emit('selectCardFunc', isSelectCnt.value);
      }
    }

    const allSelectCrse = () => {
      isSelectCnt.value = 0;
      document.querySelectorAll('.edit-card').forEach(obj => {
        obj.classList.add('is-active');
        isSelectCnt.value++;
      })
      emit('selectCardFunc',isSelectCnt.value);
    }

    return {
      isActive,
      activeMenu,
      items,
      paging,

      pagingFunc,
      selectCrse,
    }
  }
}
</script>