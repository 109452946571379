<template>
  <div class="course-list-wrapper">
    <!-- course-row -->
    <div v-for="(item, idx) in items" :key="idx" class="course-row">
      <article class="course-card edit-card" :class="`edit-card-`+idx" @click="selectCrse(idx)">
        <div class="course-image">
          <a href="javascript:" class="image-link"></a>
          <figure class="image-figure">
            <img :src="item.thumbSrc" alt="임시이미지" />
          </figure>
        </div>
        <div class="course-content">
          <div class="content-header">
            <h5 class="title"><a href="javascript:" class="title-link">{{item.crseDispNm}}</a></h5>
          </div>
          <div class="content-meta">
            <span class="text">{{item.hostNm}}</span>
            <span class="text">{{item.startDt}} - {{item.endDt}}</span>
          </div>
          <div class="content-mileage">
            <span class="text">마일리지 {{item.mileage}}</span>
          </div>
        </div>
        <div class="course-icons">
          <div class="icons">
            <i class="icon-course-collective" title="집합연수"></i>
            <i class="icon-course-cyber" title="사이버"></i>
            <i class="icon-course-book" title="교재"></i>
          </div>
        </div>
        <div class="course-actions">
          <button class="kb-btn-like"><i class="icon-like-active"></i></button>
        </div>
      </article>
    </div>
  </div>
  <!-- //course-list-wrapper -->
  <!-- pagination -->
  <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
  <!-- //pagination -->
</template>

<script>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck} from "@/assets/js/util";
import {ACT_GET_MY_KEEP_LIST} from "@/store/modules/my/my";
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: "MyTrain",
  components: {CommonPaginationFront},
  props:{
    modelValue: String
  },
  emits:['selectCardFunc', 'update:modelValue'],
  setup(props, {emit}) {
    const isActive = ref(false);
    const activeMenu = ref('연수');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const items = ref([]);
    const paging = ref(initPaging(route));

    const getMyKeepList = () => {
      store.dispatch(`my/${ACT_GET_MY_KEEP_LIST}`, {}).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res)
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getMyKeepList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {type: route.query.type, pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => route.query, () => {
      if (route.query.pageNo) {
        paging.value.pageNo = route.query.pageNo;
      } else {
        paging.value.pageNo = 1;
      }
      if (route.query.pageSize) {
        paging.value.pageSize = route.query.pageSize;
      }
    getMyKeepList();
    });

    watch(() => activeMenu.value, () => {
      getMyKeepList();
    })

    watch(() => props.modelValue, () => {
      if(props.modelValue === 'all') {
        allSelectCrse();
      }
    })

    const isSelectCnt = ref(0);
    const selectCrse = (idx) => {
      if(route.params.type === '연수'){
        if(document.querySelector('.edit-card-'+idx).classList.contains('is-active')){
          document.querySelector('.edit-card-'+idx).classList.remove('is-active');
          isSelectCnt.value--;
        }else{
          document.querySelector('.edit-card-'+idx).classList.add('is-active');
          isSelectCnt.value++;
        }
        emit('selectCardFunc',isSelectCnt.value);
      }
    }

    const allSelectCrse = () => {
      isSelectCnt.value = 0;
      document.querySelectorAll('.edit-card').forEach(obj => {
        obj.classList.add('is-active');
        isSelectCnt.value++;
      })
      emit('selectCardFunc',isSelectCnt.value);
    }

    return {
      isActive,
      activeMenu,
      items,
      paging,
      isSelectCnt,

      getMyKeepList,
      pagingFunc,
      selectCrse,
    }
  }
}
</script>